import React, { useState } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

import UniversalLink from '../../../../UniversalLink/UniversalLink'
import { Link as GatsbyLink } from 'gatsby'
import Icon from '../../../../Icon'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useMobileMenuItems } from '../../../../../hooks/useMobileMenuItems'

const MobileMenu = () => {
	const [menuIsOpen, setMenuIsOpen] = useState(false)
	const [submenuOpen, setSubmenuOpen] = useState({ menuId: null, isOpen: false })

	const menuItems = useMobileMenuItems()

	const filteredMenuItems = menuItems.filter(
		menuItem =>
			menuItem.cssClasses.find(item => item !== 'sub-menu' && item !== 'bottom-item' && item !== 'offerte-button') ||
			!menuItem.cssClasses.length
	)

	const offerteButton = menuItems.filter(menuItem => menuItem.cssClasses.find(item => item === 'offerte-button'))

	const bottomItems = menuItems.filter(menuItem => menuItem.cssClasses.find(item => item === 'bottom-item'))

	const hasLabel = menuItem => {
		return menuItem.cssClasses.find(item => item === 'label')
	}

	console.log(offerteButton)

	return (
		<MobileMenuContainer>
			<MenuIcon menuIsOpen={menuIsOpen} onClick={() => setMenuIsOpen(!menuIsOpen)}>
				<div />
				<div />
				<div />
			</MenuIcon>
			{menuIsOpen && <TriggerOverlay onClick={() => setMenuIsOpen(false)} />}
			<MenuLinksContainer menuIsOpen={menuIsOpen}>
				<ul>
					{filteredMenuItems.map(menuItem => (
						<MenuItemWrapper
							onClick={() => setSubmenuOpen({ menuId: menuItem.id, isOpen: !submenuOpen.isOpen })}
							key={menuItem.id}
						>
							<li key={menuItem.id}>
								<UniversalLink
									to={menuItem.uri}
									activeClassName={'active'}
									isDropdown={!!menuItem.cssClasses.find(item => item === 'dropdown')}
								>
									<MenuLinkLabel
										isDropdown={!!menuItem.cssClasses.find(item => item === 'dropdown')}
										lineThickness={'0.2rem'}
										background={menuItem.uri === '/offerte-aanvragen/'}
									>
										{hasLabel(menuItem) && <MenuLabel>Nieuw</MenuLabel>}

										<MenuItem>
											{menuItem.label}
											{menuItem.childItems.length > 0 && (
												<IconWrapper isDown={submenuOpen.menuId === menuItem.id && submenuOpen.isOpen}>
													<Icon width="1rem" height="1rem" color="#fff" margin="0 0 0 5px" icon={faCaretDown} />
												</IconWrapper>
											)}
										</MenuItem>

										{menuItem.childItems.length > 0 && (
											<>
												<MobileDropdownWrapper isOpen={submenuOpen.menuId === menuItem.id && submenuOpen.isOpen}>
													{submenuOpen.menuId === menuItem.id && submenuOpen.isOpen ? (
														menuItem.childItems.map(item => {
															return (
																<MobileDropdownItem key={item.url} to={item.url}>
																	{item.label}
																</MobileDropdownItem>
															)
														})
													) : (
														<></>
													)}
												</MobileDropdownWrapper>
											</>
										)}
									</MenuLinkLabel>
								</UniversalLink>
							</li>
						</MenuItemWrapper>
					))}
					<BottomItemsWrapper>
						{bottomItems.map(menuItem => (
							<MenuItemWrapper key={menuItem.id}>
								<li key={menuItem.id}>
									<UniversalLink to={menuItem.uri} activeClassName={'active'}>
										<MenuLinkLabel bottomItem={true} lineThickness={'0.2rem'}>
											<MenuItem>{menuItem.label}</MenuItem>
										</MenuLinkLabel>
									</UniversalLink>
								</li>
							</MenuItemWrapper>
						))}
					</BottomItemsWrapper>
					<MenuItemWrapper key={offerteButton[0].id}>
						<li key={offerteButton[0].id}>
							<UniversalLink to={offerteButton[0].uri} activeClassName={'active'}>
								<MenuLinkLabel lineThickness={'0.2rem'} background={true}>
									<MenuItem>{offerteButton[0].label}</MenuItem>
								</MenuLinkLabel>
							</UniversalLink>
						</li>
					</MenuItemWrapper>
				</ul>
			</MenuLinksContainer>
		</MobileMenuContainer>
	)
}

const BottomItemsWrapper = styled.div`
	padding: 5px 0;
`

const MenuItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
const IconWrapper = styled.div`
	display: flex;
	transition: rotate 0.1s, transform 0.2s ease-out;
	transform: ${props => (props.isDown ? 'rotate(180deg)' : ' ')};
	margin-left: 5px;
`

const MobileMenuContainer = styled.div``

const TriggerOverlay = styled.div`
	position: fixed;
	top: 0;
	right: 0;
`
const MenuIcon = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	border: none;
	cursor: pointer;
	z-index: 10000;

	div {
		position: relative;
		width: 1.5rem;
		height: 0.2rem;

		background: white;
		border-radius: 5px;
		transform-origin: 1px;
		transition: opacity 300ms, transform 300ms;

		:first-child {
			transform: ${({ menuIsOpen }) => (menuIsOpen ? 'rotate(45deg)' : 'rotate(0)')};
		}
		:nth-child(2) {
			opacity: ${({ menuIsOpen }) => (menuIsOpen ? '0' : '1')};
		}
		:nth-child(3) {
			transform: ${({ menuIsOpen }) => (menuIsOpen ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`

const MenuLinkLabel = styled.label`
	font-size: 16px;
	position: relative;
	font-family: ${props => props.theme.fonts.big};
	color: ${props => (props.bottomItem ? '#0685C5' : 'white')};
	cursor: pointer;
	display: flex;
	flex-direction: column;

	background-color: ${props => props.background && '#0685C5'};
	padding: ${props => props.background && '12px 20px'};
	border-radius: ${props => props.background && '5px'};

	padding-bottom: ${props => !props.background && '5x'};

	&:hover {
		background-color: ${props => props.background && '#109be0'};
	}

	::after {
		content: '';
		display: block;
		position: absolute;
		height: ${({ lineThickness }) => lineThickness};
		width: 100%;
		background-color: white;
		transform-origin: right top;
		transform: scale(0, 1);
		transition: color 0.1s, transform 0.2s ease-out;
	}

	:hover::after,
	:focus::after,
	&.active::after {
		content: '';
		display: ${props => (props.background || props.isDropdown ? 'none' : 'block')};
		position: absolute;
		top: 25px;
		height: ${({ lineThickness }) => lineThickness};
		width: 100%;
		background-color: white;
		transform-origin: left top;
		transform: scale(1, 1);
		transition: color 0.1s, transform 0.2s ease-out;
	}
`

const MenuLabel = styled.div`
	border-radius: 3px;
	width: fit-content;
	padding: 5px 10px;
	color: black;
	font-size: 11px;
	position: absolute;
	left: -67px;
	bottom: 0;
	background-color: #b0bec5;

	&::after {
		content: '';
		position: absolute;
		left: 95%;
		top: 39%;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #b0bec5;
		clear: both;
		transform: rotate(270deg);
	}
`

const MobileDropdownItem = styled(GatsbyLink)`
	margin-right: 0 !important;
	color: #ffffff;
	font-size: 16px !important;

	&:hover {
		color: #b1bec5;
	}
`

const DropdownHoverWrapper = styled.div`
	display: flex;
	width: 100%;
	height: ;
	position: absolute;
`

const MenuItemWrapper = styled.div``

const MobileDropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 140px;
	font-size: 13px;
	border-radius: 3px;
	color: white;
	font-family: Poppins;
	max-width: 150px;

	transition: opacity 0.1s, transform 0.2s ease-out;

	height: ${props => (props.isOpen ? '100%' : 0)};
	gap: ${props => (props.isOpen ? '10px;' : 0)};
	padding: ${props => (props.isOpen ? '15px 10px;' : 0)};
	margin-top: ${props => (props.isOpen ? '10px;' : 0)};

	${MenuLinkLabel}:hover & {
		opacity: 100;
	}

	${DropdownHoverWrapper}:hover & {
		opacity: 100;
	}
`

const MenuLinksContainer = styled.nav`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
	width: 75%;
	background: ${transparentize(0.1, 'black')};
	transform: ${({ menuIsOpen }) => (menuIsOpen ? 'translateX(0)' : 'translateX(100%)')};
	transition: transform 300ms;
	overflow: scroll;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		margin-top: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	a {
		font-size: 1.5rem;
	}
`

export default MobileMenu
